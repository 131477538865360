/* color variables */
$cinch-title: rgb(56, 118, 29);

$error: #c81414;
$error-background: #fad2d2;
$error-highlight: #aa1212;

$normal-background: #f2f2f2;
$normal-border: #ccc;
$normal-font-color: #686d77;
$normal-shadow: rgba(64,60,67,.16);
$white-font-color: #ffffff;
$black-font-color: black;

$success: #28d050;
$success-background: rgb(191, 240, 203);
$success-highlight: #1da53c;
