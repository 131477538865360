/* legacy font variables */
$font-large: 1.05rem;
$font-normal: 1rem;
$font-little-small: .95rem;
$font-small: .875rem;
$font-extra-small: .75rem;

/*custom fonts*/
@font-face {
    font-family: "Mark OT";
    src: url('../../assets/Fonts/MarkOTReg.eot');
}
/**/