@import './app/styles/colors';
@import './app/styles/fonts';
@import './app/styles/material';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.main-section {
  padding: 10px;
  border: solid 1px $normal-border;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.error-message {
  text-align: center;
  padding: 15px;
  font-weight: bold;
  background-color: $error-background;
  color: $error;

  @media screen and (max-width: 998px) {
    padding: 5px;
  }
}

header {
    .heading {
        background-color: $normal-background;
        padding: 2px;
        display: flex;
        flex-direction: row;

        h2 {
            flex: 1;
            text-align: center;
            margin: 12px 0 0 0;
        }
    }
}

a {
  margin-right: 20px;
  color: $normal-font-color;

  &:hover {
      text-decoration: underline;
      color: $success;
  }
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;
}

.main-content {
  padding: 5px;
  color: $normal-font-color;

  h2, h3 {
      text-align: center;
  }
}

.sub-content {
  display: flex;
  flex-direction: row;
  padding: 10px;

  .sub-section {
      .form-fields {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px;
      }
  }

  @media screen and (max-width: 998px) {
      flex-direction: column;
      padding: 0;
  }
}

.button-group {
  padding: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
      margin-right: 5px;
      width: 110px;
  }

  @media screen and (max-width: 998px) {
      padding: 0;
  }
}

.section-wrapper {
  border: solid 1px $normal-border;
  border-radius: 8px;
  padding: 10px;

  @media screen and (max-width: 998px) {
    padding: 3px;
}
}

.sub-section {
  margin-right: 5px;

  h5 {
      text-align: center;
  }

  @media screen and (max-width: 998px) {
      margin-right: 0;
      margin-bottom: 5px;
  }
}

.card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media screen and (max-width: 998px) {
     min-height: 350px;
  }
}

.error-focus {
  border: solid 1px $error !important;
  box-shadow: 0 1px 1px 1px $error-background !important;
}

.fields {
  flex-direction: column;
  padding: 2px 2px;
  min-height: 80px;

  small {
      color: $error;
      font-size: $font-extra-small;
      padding: 4px;
      position: relative;
      bottom: 13px;
      white-space: nowrap;
  }
}

.fields, .fields-with-value {
  display: flex;
  color: $normal-font-color;

  label, span, a, p {
      font-size: $font-small;
  }

  label {
      font-weight: 600;
      padding: 1px;
      white-space: nowrap;
  }

  span, a {
      padding: 4px 5px;
      white-space: nowrap;
  }

  p {
      padding: 2px 5px;
      margin: 0;
  }
}

.fields-with-value {
  flex-direction: row;
  padding: 2px 2px;
}

.same-line-fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pull-right {
  float: right;
}

.card-main-content {
  flex: 1;
}

.card-sub-content {
  flex: 2;
}

.err-msg {
  .mdc-snackbar__surface {
    background-color: $error-background !important;
    .mdc-snackbar__label {
      color: $error;
      font-weight: 550;
    }
  }
}
.sucs-msg {
  .mdc-snackbar__surface {
    background-color: $success-background !important;
    .mdc-snackbar__label {
      color: $cinch-title;
      font-weight: 550;
    }
  }
}

.success-message {
  padding: 15px;
  text-align: center;
  font-size: $font-large;
  font-weight: 500;
  color: $cinch-title;
  
  display: flex;
  align-items: center;
  justify-content: center;

  span {
      background-color: $success-background;
      border-radius: 6px;
      padding: 15px;
  }
}

.no-data-message {
  text-align: center;
  min-height: 51px;
  width: 100%;

  span {
    font-size: 15px;
  }
}

.process-button {
  width: 165px !important;
  .spinner {
    float: left;
    height: 20px !important;
    width: 30px !important;
  
    ::ng-deep .mat-mdc-progress-spinner {
        height: 20px !important;
    }
  }
}
