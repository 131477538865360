.mat-mdc-unelevated-button {
    &:hover {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }
  }
  
  .mat-mdc-unelevated-button.mat-primary {
    &:disabled {
      width: 140px;
      height: 35px;
    }
  
    &:not(:disabled) {
      background-color: $success;
      width: 140px;
      height: 35px;
      color: $black-font-color;
      outline: none;
    }
  }
  
  .mat-mdc-unelevated-button.mat-warn {
    &:disabled {
      width: 140px;
      height: 35px;
    }
  
    &:not(:disabled) {
      background-color: $error;
      border: solid 1px $error;
      width: 140px;
      height: 35px;
      color: $white-font-color;
    }
  }
  
  .mat-mdc-slide-toggle .mdc-switch {
    outline: none;
  }
  
  .mdc-form-field > label {
    margin-bottom: 0;
  }
  
  .mat-mdc-form-field {
    padding: 5px;
    width: 300px;
  
    .mat-mdc-form-field-flex {
      height: 35px;
  
      .mat-mdc-form-field-infix {
        bottom: 7px;
      }
  
      .mat-mdc-floating-label.mdc-floating-label {
        padding-bottom: 20px;
      }
    }
  }
  
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-width: 2px;
  }
  
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: $success !important;
  }
  
  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-left: none;
  }
  
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    color: $cinch-title;
  }
  
  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: $error;
  }
  
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: $normal-font-color;
  }
  
  .mat-mdc-progress-spinner circle, .mat-mdc-spinner circle {
    stroke: $success;
  }
  
  .mat-mdc-snack-bar-container {
  
    .mat-mdc-simple-snack-bar .mat-mdc-snack-bar-actions {
        font-weight: 700;
      
        button {
          background-color: $normal-shadow;
          border-radius: 8px;
  
          .mdc-button__label {
            color: $error !important;
          }
  
          &:hover {
            border: solid 1px $error-highlight;
            border-radius: 8px;
            background-color: $error;
            
            .mdc-button__label {
              color: $white-font-color !important;
            }
          }
        }
    }
  
    @media screen and (max-width: 998px) {
        top: 136px;
    }
  }
  
  .mat-mdc-checkbox {
    position: relative;
    top: 3px;
  }
  
  .mat-mdc-checkbox-indeterminate.mat-primary .mat-mdc-checkbox-background, .mat-mdc-checkbox-checked.mat-primary .mat-mdc-checkbox-background {
    background-color: $success-highlight;
  }
  
  .mat-mdc-table {
    thead {
        tr {
            background-color: $normal-background;
  
            .mdc-data-table__header-cell {
              color: $normal-font-color;
            }
        }
    }
  }
  
  .mat-mdc-radio-button {
    label {
      margin-bottom: 0;
    }
  }  